import { StorageKeys } from "@/shared/enums/StorageKeys.enum";
import { ObjectUtil } from "@/shared/utils/object-util";
import { concatAll } from "rxjs";
import { ConfirmationModelComponent } from "../../components/confirmation-model/confirmation-model.component";
import {  SecurityContext } from "@angular/core";
import { Subject, catchError, map, of, takeUntil } from "rxjs";

declare const d3: any;
let hoverTags = {
    "waterCooledChiller": {
        "chiller": {
            'leaving': ["chilled", "water", "temp", "leaving", "sensor"],
            'entering': ["chilled", "water", "temp", "entering", "sensor"],
            'setPoint': ["chilled", "water", "sp"]
        },
        "condensor": {
            'leaving': ["condenser", "leaving", "water", "temp", "sensor"],
            'entering': ["condenser", "water", "temp", "entering", "sensor"],
            'setPoint': ["condenser", "water", "sp"]
        }
    },
    "airCooledChiller": {
        "chiller": {
            'leaving': ["chilled", "leaving", "water", "temp", "sensor"],
            'entering': ["chilled", "water", "temp", "entering", "sensor"],
            'setPoint': ["chilled", "water", "sp"]
        }
    },
    "steamBoiler": {
        "boiler": {
            'leaving': ["hot", "leaving", "temp", "water", "sensor"],
            'entering': ["hot", "entering", "temp", "water", "sensor"],
            'setPoint': ["boiler", "water", "sp"]
        }
    },
    'condenserBoiler': {
        "boiler": {
            'leaving': ["hot", "leaving", "temp", "water", "sensor"],
            'entering': ["hot", "entering", "temp", "water", "sensor"],
            'setPoint': ["boiler", "water", "sp"]
        }
    }
}

let siteService,helperService,dialog,dom;
let plantData;
let loginUserTypeData,chartWidth,chartHeight;
export function generateBulletChart(dataNode, widgetType, PlantData, SiteService, HelperService, Dialog, Dom, LoginUserTypeData, chartWidth = 240, chartHeight = 40, clickState = false) {
    loginUserTypeData = LoginUserTypeData
    siteService = SiteService;
    helperService = HelperService;
    dialog = Dialog
    plantData =  PlantData;
    dom = Dom;
    chartWidth = chartWidth;
    chartHeight = chartHeight;
    const bulletChartWidget = document.createElement('div');
    bulletChartWidget.setAttribute('id', 'bulletChartWidget' + "-" + widgetType + "-" + clickState);
    bulletChartWidget.classList.add("bulletChartWidget");
    bulletChartWidget.classList.add("m-t-20");
    if (bulletChartIsEnterTemp(dataNode, widgetType) && bulletChartIsLeaveTemp(dataNode, widgetType)) {
        bulletChartWidget.classList.add(widgetType + '-bulletChart');
    } else if (!bulletChartIsEnterTemp(dataNode, widgetType) && bulletChartIsLeaveTemp(dataNode, widgetType)) {
        bulletChartWidget.classList.add(widgetType + '-leaveTempbulletChart');
    } else if (bulletChartIsEnterTemp(dataNode, widgetType) && !bulletChartIsLeaveTemp(dataNode, widgetType)) {
        bulletChartWidget.classList.add(widgetType + '-enterTempbulletChart');
    } else if (!bulletChartIsEnterTemp(dataNode, widgetType) && !bulletChartIsLeaveTemp(dataNode, widgetType) && getBulletWidgetData(dataNode, 'setPoint', widgetType)) {
        bulletChartWidget.classList.add(widgetType + '-setpointTempbulletChart');
    }

    const bulletChartPointLabelContent = document.createElement('div');
    bulletChartPointLabelContent.classList.add("row");
    bulletChartWidget.appendChild(bulletChartPointLabelContent);

    const bulletChartPointLabelLeftContent = document.createElement('div');
    bulletChartPointLabelLeftContent.classList.add("col-3");
    bulletChartPointLabelLeftContent.classList.add("left-align");
    bulletChartPointLabelLeftContent.classList.add("p-l-5");
    bulletChartPointLabelLeftContent.classList.add("p-r-5");
    bulletChartPointLabelContent.appendChild(bulletChartPointLabelLeftContent);

    if (bulletChartIsLeaveTemp(dataNode, widgetType)) {
        const leftPointContent = document.createElement('div');

        const leftIconContent = document.createElement('i');
        leftIconContent.classList.add("fa");
        leftIconContent.classList.add("fa-square");
        leftIconContent.classList.add("leavingColor");
        leftPointContent.appendChild(leftIconContent);

        const leftLabelTextContent = document.createElement('span');
        leftLabelTextContent.classList.add("bulletValueType");
        leftLabelTextContent.classList.add("p-l-5");
        leftLabelTextContent.innerHTML = "Leaving";
        leftPointContent.appendChild(leftLabelTextContent);


        const leftLabelValueContent = document.createElement('div');
        leftLabelValueContent.classList.add("bulletTempLabel");
        leftLabelValueContent.classList.add("leavingColor");
        leftLabelValueContent.innerHTML = `${getBulletWidgetData(dataNode, 'leaveTemp', widgetType)}`;
        leftPointContent.appendChild(leftLabelValueContent);

        bulletChartPointLabelLeftContent.appendChild(leftPointContent);
    }

    const bulletChartPointLabelCenterContent = document.createElement('div');
    bulletChartPointLabelCenterContent.classList.add("col-5");
    bulletChartPointLabelCenterContent.classList.add("center-align");
    bulletChartPointLabelCenterContent.classList.add("p-l-5");
    bulletChartPointLabelCenterContent.classList.add("p-r-5");
    bulletChartPointLabelContent.appendChild(bulletChartPointLabelCenterContent);


    let bulletChartHeaderTitle = document.createElement('div');
    bulletChartHeaderTitle.classList.add("bulletChartPointName")
    bulletChartHeaderTitle.innerHTML = getBulletWidgetData(dataNode, 'widgetHeader', widgetType);

    bulletChartPointLabelCenterContent.appendChild(bulletChartHeaderTitle);


    if (bulletChartIsSetPointTemp(dataNode, widgetType)) {
        const setPointLabelContent = document.createElement('div');
        setPointLabelContent.classList.add("bulletChartSetPointText")
        if (clickState) {
            setPointLabelContent.appendChild(setPointDropDown(dataNode, widgetType))
        } else {
            setPointLabelContent.innerHTML = `SETPOINT <span>${getBulletWidgetData(dataNode, 'setPoint', widgetType)}</span>`;
        }
        bulletChartPointLabelCenterContent.appendChild(setPointLabelContent);
    }

    const bulletChartPointLabelRightContent = document.createElement('div');
    bulletChartPointLabelRightContent.classList.add("col-3");
    bulletChartPointLabelRightContent.classList.add("right-align");
    bulletChartPointLabelRightContent.classList.add("p-l-5");
    bulletChartPointLabelRightContent.classList.add("p-r-5");
    bulletChartPointLabelContent.appendChild(bulletChartPointLabelRightContent);

    if (bulletChartIsEnterTemp(dataNode, widgetType)) {
        const rightPointContent = document.createElement('div');
        rightPointContent.innerHTML = `<div><span class="bulletValueType">Entering</span>
                                            <i class="fa fa-square enterColor" aria-hidden="true"></i>    
                                        </div>
                                        <div class="bulletTempLabel enterColor">
                                            ${getBulletWidgetData(dataNode, 'enterTemp', widgetType)}
                                        </div>`

        bulletChartPointLabelRightContent.appendChild(rightPointContent);
    }

    const bulletChartContent = document.createElement('div');

    let bulletChartData = getBulletWidgetData(dataNode, 'getData', widgetType);
    let bulletChartmargin = { top: 5, right: 20, bottom: 20, left: 20 },
        width = chartWidth - bulletChartmargin.left - bulletChartmargin.right,
        height = chartHeight - bulletChartmargin.top - bulletChartmargin.bottom;

    let bulletChartSvg = document.createElement("div");
    bulletChartSvg.classList.add("bulletChartHeight");
    let bulletChart = d3.bullet(chartWidth - 50, 10, true)
        .width(width)
        .height(height);
    d3.select(bulletChartSvg)
        .data(bulletChartData)
        .append("svg")
        .attr("class", "bullet")
        .attr("id", widgetType + "-" + clickState)
        .attr("width", width + 20)
        .attr("height", 80)
        .append("g")
        .attr("class", "bulletChartrect")
        .attr("transform", "translate(5,10)")
        .call(bulletChart);
    bulletChartContent.appendChild(bulletChartSvg);

    if (bulletChartIsLeaveTemp(dataNode, widgetType) && bulletChartIsEnterTemp(dataNode, widgetType)) {
        const bulletChartDelta = document.createElement('div');
        bulletChartDelta.classList.add("bulletDeltaText");
        bulletChartDelta.innerHTML = `DELTA  <span class="deltaText"><span class="triangleImg">Δ</span> ${getBulletWidgetData(dataNode, 'delta', widgetType)} </span>`
        bulletChartContent.append(bulletChartDelta);
    }

    bulletChartWidget.appendChild(bulletChartContent);
    return bulletChartWidget;
}

function getBulletWidgetData(data, key, widgetType) {

    let bulletleaveTemp: any = {}; let bulletEnterTemp: any = {}; let bulletSetPointTemp: any = {};
    let convertValue, minValue, maxValue, convertedMaxValue, widgetHeader, outerLine1, outerline2, maxNumber, minNumber;

    data?.data?.point.forEach(pointData => {

        if (widgetType == 'condenser') {
            if (hoverTags[data.class].condensor.leaving.every(value => pointData.tags.includes(value))) {
                bulletleaveTemp = pointData;
            }
            if (hoverTags[data.class].condensor.entering.every(value => pointData.tags.includes(value))) {
                bulletEnterTemp = pointData;
            }
            if (hoverTags[data.class].condensor.setPoint.every(value => pointData.tags.includes(value))) {
                bulletSetPointTemp = pointData;
            }

            widgetHeader = 'CONDENSER WATER';
            outerLine1 = +3;
            outerline2 = -3;
        }

        if (widgetType == 'evaporator') {
            if (hoverTags[data.class].chiller.leaving.every(value => pointData.tags.includes(value))) {
                bulletleaveTemp = pointData;
            }
            if (hoverTags[data.class].chiller.entering.every(value => pointData.tags.includes(value))) {
                bulletEnterTemp = pointData;
            }
            if (hoverTags[data.class].chiller.setPoint.every(value => pointData.tags.includes(value))) {
                bulletSetPointTemp = pointData;
            }

            widgetHeader = 'CHILLED WATER';
            outerLine1 = -3;
            outerline2 = +3;
        }


        if (widgetType == 'boiler') {
            if (hoverTags[data.class].boiler.leaving.every(value => pointData.tags.includes(value))) {
                bulletleaveTemp = pointData;
            }
            if (hoverTags[data.class].boiler.entering.every(value => pointData.tags.includes(value))) {
                bulletEnterTemp = pointData;
            }
            if (hoverTags[data.class].boiler.setPoint.every(value => pointData.tags.includes(value))) {
                bulletSetPointTemp = pointData;
            }

            widgetHeader = 'HOT WATER';
            outerLine1 = -3;
            outerline2 = +3;
        }
    });


    if (Object.keys(bulletleaveTemp).length && bulletleaveTemp.data.length) {

        let valueArr = [];
        valueArr.push(bulletleaveTemp.minVal);
        valueArr.push(bulletleaveTemp.maxVal);
        if (Object.keys(bulletEnterTemp).length) {
            valueArr.push(bulletEnterTemp.minVal);
            valueArr.push(bulletEnterTemp.maxVal);
        }
        // let valueArr = bulletleaveTemp.data.map(point => {
        //     const val = point?.val ? (Number(point?.val?.split(" ")[0]).toFixed(2)): null;
        //     return val;
        // });
        minNumber = Math.min.apply(Math, valueArr);
        maxNumber = Math.max.apply(Math, valueArr);;
        
        convertValue = minNumber;
        convertedMaxValue = maxNumber - minNumber;
        minValue = `${minNumber} ${bulletleaveTemp.unit}`;
        maxValue = `${maxNumber} ${bulletleaveTemp.unit}`;
    } else if (Object.keys(bulletEnterTemp).length && bulletEnterTemp.data.length) {
        // let valueArr = bulletEnterTemp.data.map(point => {
        //     const val = point?.val ? (Number(point?.val?.split(" ")[0]).toFixed(2)): null;
        //     return val;
        // });
        // minNumber = Math.min.apply(Math, valueArr);
        // maxNumber = Math.max.apply(Math, valueArr);

        let valueArr = [];
        valueArr.push(bulletEnterTemp.minVal);
        valueArr.push(bulletEnterTemp.maxVal);
        if (Object.keys(bulletleaveTemp).length) {
            valueArr.push(bulletleaveTemp.minVal);
            valueArr.push(bulletleaveTemp.maxVal);
        }
        minNumber = Math.min.apply(Math, valueArr);
        maxNumber = Math.max.apply(Math, valueArr);
        
        convertValue = minNumber;
        convertedMaxValue = maxNumber - minNumber;
        minValue = `${minNumber} ${bulletEnterTemp.unit}`;
        maxValue = `${maxNumber} ${bulletEnterTemp.unit}`;
    }


    switch (key) {
        case 'widgetHeader':
            return widgetHeader;
        case "leaveTemp":
            if (bulletChartIsLeaveTemp(data, widgetType)) {
                let unit = bulletleaveTemp.unit;
                bulletleaveTemp = bulletleaveTemp.data[bulletleaveTemp.data.length - 1].val;
                return Number(bulletleaveTemp.split(" ")[0]).toFixed(2) + unit;
            } else {
                return "";
            }
        case "enterTemp":
            if (bulletChartIsEnterTemp(data, widgetType)) {
                let unit = bulletEnterTemp.unit;
                bulletEnterTemp = bulletEnterTemp.data[bulletEnterTemp.data.length - 1].val;
                return Number(bulletEnterTemp.split(" ")[0]).toFixed(2) + unit;
            } else {
                return "";
            }
        case "setPoint":
            if (bulletChartIsSetPointTemp(data, widgetType)) {
                let unit = bulletSetPointTemp.unit;
                bulletSetPointTemp = (bulletSetPointTemp?.writableData && bulletSetPointTemp?.writableData[0]?.val) ? bulletSetPointTemp?.writableData[0]?.val:  bulletSetPointTemp?.data[bulletSetPointTemp?.data?.length - 1].val;
                return Number(bulletSetPointTemp.split(" ")[0]).toFixed(2) + unit;
            } else {
                return "";
            }
        case "getData":
            let convertedLeaveTemp;
            let convertedEnterTemp;
            let convertedSetPointTemp;

            if (bulletChartIsLeaveTemp(data, widgetType)) {
                const val = bulletleaveTemp.data[bulletleaveTemp.data.length - 1].val ? Number(bulletleaveTemp.data[bulletleaveTemp.data.length - 1].val?.split(" ")[0]): null;
                convertedLeaveTemp = val - convertValue;
            } else {
                convertedLeaveTemp = convertValue;
            }

            if (bulletChartIsEnterTemp(data, widgetType)) {
                convertedEnterTemp = Number(bulletEnterTemp.data[bulletEnterTemp.data.length - 1].val?.split(" ")[0]) - convertValue;;
            } else {
                convertedEnterTemp = convertValue;
            }

            if (bulletChartIsSetPointTemp(data, widgetType)) {
                convertedSetPointTemp =(bulletSetPointTemp?.writableData && bulletSetPointTemp?.writableData[0]?.val) ? bulletSetPointTemp?.writableData[0]?.val:  bulletSetPointTemp?.data[bulletSetPointTemp?.data?.length - 1].val - convertValue
            } else {
                convertedSetPointTemp = convertValue;
            }

            let ranges, setPointMarkers, deltaLine1, deltaLine2, deltaJoinLine, deltaFillData;

            if (bulletChartIsLeaveTemp(data, widgetType) && bulletChartIsEnterTemp(data, widgetType)) {
                ranges = [convertedLeaveTemp, convertedEnterTemp, convertedMaxValue];
                deltaLine1 = [convertedLeaveTemp];
                deltaLine2 = [convertedEnterTemp];
                deltaJoinLine = [{
                    x1: convertedLeaveTemp + outerLine1,
                    x2: convertedEnterTemp + outerline2
                }];
                deltaFillData = [{
                    x1: convertedLeaveTemp,
                    x2: convertedEnterTemp
                }]
            } else if (!bulletChartIsLeaveTemp(data, widgetType) && bulletChartIsEnterTemp(data, widgetType)) {
                ranges = [convertedEnterTemp, convertedMaxValue];
                deltaLine1 = [];
                deltaLine2 = [];
                deltaJoinLine = [];
                deltaFillData = [];
            } else if (bulletChartIsLeaveTemp(data, widgetType) && !bulletChartIsEnterTemp(data, widgetType)) {
                ranges = [convertedLeaveTemp, convertedMaxValue];
                deltaLine1 = [];
                deltaLine2 = [];
                deltaJoinLine = [];
                deltaFillData = [];
            } else if (!bulletChartIsLeaveTemp(data, widgetType) && !bulletChartIsEnterTemp(data, widgetType) && bulletChartIsSetPointTemp(data, widgetType)) {
                ranges = [0, convertedMaxValue];
                deltaLine1 = [];
                deltaLine2 = [];
                deltaJoinLine = [];
                deltaFillData = [];
            }

            if (bulletChartIsSetPointTemp(data, widgetType)) {
                setPointMarkers = [convertedSetPointTemp];
            } else {
                setPointMarkers = [];
            }
            let bulletChartObj = [
                {
                    "ranges": ranges,
                    "markers": setPointMarkers,
                    "minValue": minValue,
                    "maxValue": maxValue,
                    "line1": deltaLine1,
                    "line2": deltaLine2,
                    "joinLine": deltaJoinLine,
                    "polygon": deltaFillData
                }
            ];

            return bulletChartObj;

        case "delta":
            if (bulletChartIsLeaveTemp(data, widgetType) && bulletChartIsEnterTemp(data, widgetType)) {
                return Math.abs(Number(bulletleaveTemp.data[bulletleaveTemp.data.length - 1].val.split(' ')[0]) - Number(bulletEnterTemp.data[bulletEnterTemp.data.length - 1].val.split(' ')[0])).toFixed(2) + bulletleaveTemp.unit ;
            } else {
                return "";
            }
        default:
            return "";
    }

}

function findDecimal(value) {
    let decimal;
    if (Math.floor(value) === value) return 0;
    decimal = value.toString().split(".")[1].length || 0;
    decimal = decimal < 2 ? decimal : 2;
    return decimal;
}

function setpointsarray(setPointobj) {
    let arr = [];
    let min = Number(setPointobj.minVal);
    let max = Number(setPointobj.maxVal);
    let increment = Number(setPointobj.incrementVal);

    for (let i = min; i <= max; i += increment) {
        i= parseFloat(i.toFixed(findDecimal(increment)))
        arr.push(i);
    }
    return arr;
}

async function openConfirmationMessageOnPriorityChange(id, pointData: any, val, type, name, point,dataNode){
    let htmlContent = '';
    const dialogRef = dialog.open(ConfirmationModelComponent, {
      panelClass: 'fs-mat-dialog-container',
      width: '450px',
      disableClose: true,
    });
    // tslint:disable-next-line
    htmlContent = `<div>Currently the ${name} has the following higher priorities in effect.\n Would you like to
    override those priorities? </div>`;

    dialogRef.componentInstance.title = 'Confirmation';
    dialogRef.componentInstance.htmlContent = dom.sanitize(SecurityContext.HTML, htmlContent);
    const alert = await dialogRef.afterClosed().toPromise();
    onPopupConfirm(alert, id, pointData, val, type, point,dataNode );
}

function onPopupConfirm(alert, id, pointData: any, val, type, point,dataNode) {
    if (alert === 'confirm') {
      forceOverrideData(id, pointData, val,dataNode,point,type);
    } 
  }


function setPriorityData(id, priorityArray, val) {
  return { 
    ref: id,
    level: String(priorityArray.level),
    who: `web_${getUserName()}`,
    duration: '0ms',
    val:val
  };
}


function getUserName() {
    if (Object.keys(plantData?.userInfo)?.length) {
        return plantData.userInfo['firstName'] + ' ' + plantData.userInfo['lastName'];
    } else {
        return null;
    }
}

function writeDataToHaystack(dataObj: any, reason, prevData) {
    return siteService.updateBulkWritablePointData(dataObj, reason);
}

function forceOverrideData(id, prevData: any, val,dataNode,setPointobj,type) {
    const overRideArrayLevel = [];
    let overrideLevel8: any = {};
    overrideLevel8 = setPriorityData(id, {level: '10'},  Number(val?.split(" ")[0]));
    overRideArrayLevel.push(overrideLevel8);
    // If priority array data is empty then setting the val.
    prevData.map(priorityArray => {
      // If the priority level is less than 10 returning the val as 'N'
      if (priorityArray.level < 10) {
        overrideLevel8 = setPriorityData(id, priorityArray, 'N');
        overRideArrayLevel.push(overrideLevel8);
      }
    });
    writeDataToHaystack(overRideArrayLevel, '', prevData).pipe(map(helperService.stripHaystackTypeMapping)).subscribe(
        (res: any) => {
        setPointobj.writableData[0] = res.rows[0].data[0];
          let string, divIdTOBeRemoved;
          let index =  dataNode.data.point.findIndex(x => x.id == setPointobj.id);
          if (type == "condenser") {
              string = 'Condenser water setpoint'
              divIdTOBeRemoved = "condenserWaterContainer"
          } else if (type == "evaporator") {
              string = 'Chilled water setpoint'
              divIdTOBeRemoved = "chilledWaterContainer"
          } else if (type == "boiler") {
              string = 'Hot water setpoint'
              divIdTOBeRemoved = "boilerBulletContainer"
          }
  
          let selectedDom =  document.getElementById("bulletChartWidget-" + type + "-true");
          selectedDom.remove();
          document.getElementById(divIdTOBeRemoved).appendChild(generateBulletChart(dataNode, type, plantData, siteService, helperService, dialog, dom, loginUserTypeData, chartWidth , chartHeight, true));
    });
  }

function setPointDropDown(dataNode,type) {
    let setPointobj:any = getSetPointObj(type, dataNode);
    const myParent = document.createElement('div');
    myParent.classList.add("setPointDropDownContainer");
    let array;
    array = setpointsarray(setPointobj);
    const selectList = document.createElement("select");
    if(loginUserTypeData.isPublic || loginUserTypeData.isVofm){
        selectList.classList.add("sharedSetPointDropDown");
    }
    const self = this;
    selectList.id = "mySelect" + "-" + type;
    selectList.onchange = function (event) {
        const newValue = event.target['value'];

        const checkProrityArray = setPointobj.writableData.find(priorityArray => Number(priorityArray.level) < 10);
        if (checkProrityArray) {
          openConfirmationMessageOnPriorityChange(setPointobj.id, setPointobj.writableData, newValue, type, setPointobj.dis, setPointobj,dataNode);
        } else {
          forceOverrideData(setPointobj.id, setPointobj.writableData, newValue,dataNode,setPointobj,type);
        }

    };
    selectList.style.margin = 6 + "px";
    const content = document.createElement("div");
    content.classList.add('d-flex');
    const setPointHeading = document.createElement("div");
    setPointHeading.innerHTML = "SETPOINT"
    content.appendChild(setPointHeading)
    content.appendChild(selectList)
    myParent.appendChild(content);
    for (let obj of array) {
        const option = document.createElement("option");
        option.value = obj;
        option.text = obj + setPointobj.unit;
        selectList.appendChild(option);
    }
    if (setPointobj.writableData?.length) {
        selectList.value = setPointobj.writableData[0]?.val;
    }
    return myParent;
}

function savePlant(data) {
    let plantData = ObjectUtil.deepClone(data)
    let plantConfigList = JSON.parse(localStorage.getItem(StorageKeys.PLANTCONFIG));
    if (plantConfigList == null) {
        localStorage.setItem(StorageKeys.PLANTCONFIG, JSON.stringify([plantData]));
    } else if (plantConfigList.length > 0) {
        let index = plantConfigList.findIndex(plant => plant.id === plantData.id);
        if (index == -1) {
            plantConfigList.push(plantData);
        } else {
            plantConfigList[index] = plantData;
        }
        localStorage.setItem(StorageKeys.PLANTCONFIG, JSON.stringify(plantConfigList));
    }
}

function bulletChartIsLeaveTemp(data, widgetType) {
    let isPointSelected;
    data?.data?.point.forEach(pointData => {
        if (widgetType == 'condenser') {
            if (hoverTags[data.class].condensor.leaving.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        } else if (widgetType == 'evaporator') {
            if (hoverTags[data.class].chiller.leaving.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        } if (widgetType == 'boiler') {
            if (hoverTags[data.class].boiler.leaving.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        }
    });

    if (isPointSelected?.data?.length && isPointSelected?.data[isPointSelected.data.length - 1].val != null) {
        return true;
    } else {
        return false;
    }
}

function bulletChartIsEnterTemp(data, widgetType) {
    let isPointSelected;
    data?.data?.point.forEach(pointData => {
        if (widgetType == 'condenser') {
            if (hoverTags[data.class].condensor.entering.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        } else if (widgetType == 'evaporator') {
            if (hoverTags[data.class].chiller.entering.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        } if (widgetType == 'boiler') {
            if (hoverTags[data.class].boiler.entering.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        }
    });

    if (isPointSelected?.data?.length && isPointSelected?.data[isPointSelected.data.length - 1].val != null) {
        return true;
    } else {
        return false;
    }
}

function bulletChartIsSetPointTemp(data, widgetType) {
    let isPointSelected;
    data?.data?.point.forEach(pointData => {
        if (widgetType == 'condenser') {
            if (hoverTags[data.class].condensor.setPoint.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        } else if (widgetType == 'evaporator') {
            if (hoverTags[data.class].chiller.setPoint.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        } if (widgetType == 'boiler') {
            if (hoverTags[data.class].boiler.setPoint.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        }
    });

    if (isPointSelected?.data?.length && isPointSelected?.data[isPointSelected.data.length - 1].val != null) {
        return true;
    } else {
        return false;
    }
}


function getSetPointObj(widgetType, data) {
    let isPointSelected = {};
    
    data?.data?.point.forEach(pointData => {
        if (widgetType == 'condenser') {
            if (hoverTags[data.class].condensor.setPoint.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        } else if (widgetType == 'evaporator') {
            if (hoverTags[data.class].chiller.setPoint.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        } if (widgetType == 'boiler') {
            if (hoverTags[data.class].boiler.setPoint.every(value => pointData.tags.includes(value))) {
                isPointSelected = pointData;
            }
        }
    });

    return isPointSelected;
}