export const defaultClickViewPoint = {
    "waterCooledChiller": [
      ["chilled", "leaving", "water", "temp", "sensor"],
      ["chilled", "water", "temp", "entering", "sensor"],
      ["chilled", "water", "sp"],
      ["condenser", "leaving", "water", "temp", "sensor"],
      ["condenser", "water", "temp", "entering", , "sensor"],
      ["condenser", "water", "sp"],
      ['chiller', 'enable'],
      ['chiller', 'run', 'time'],
      ['power', 'elec', 'chiller'],
      ['energy', 'chiller'],
      ['chiller', 'cmd', 'run'],
      ['chiller', 'efficiency'],
      ['chiller', 'energy'],
      ['chiller', 'alarm'],
      ['chiller', 'status'],
      ['chiller', 'load'],
      ['building', 'schedule']
    ],
    "airCooledChiller": [
      ['chiller', 'run', 'time'],
      ['chiller', 'enable'],
      ['power', 'elec', 'chiller'],
      ['energy', 'chiller'],
      ['chiller', 'cmd', 'run'],
      ["chilled", "water", "temp", "leaving", "sensor"],
      ["chilled", "water", "temp", "entering", "sensor"],
      ["chilled", "water", "sp"],
      ["condenser", "water", "temp", "leaving", "sensor"],
      ["condenser", "water", "temp", "entering", "sensor"],
      ["condenser", "water", "sp"],
      ['chiller', 'efficiency'],
      ['chiller', 'energy'],
      ['chiller', 'alarm'],
      ['chiller', 'status'],
      ['chiller', 'load'],
      ['building', 'schedule']
    ],
    "coolingTowerSingleFan": [
      ['coolingTower', 'enable'],
      ['coolingTower', 'run', 'time'],
      ['coolingTower', 'power', 'elec'],
      ['coolingTower', 'energy'],
      ['coolingTower', 'run', 'cmd'],
      ['coolingTower', 'alarm'],
      ['coolingTower', 'status'],
      ['building', 'schedule'],
      ['vfd', 'speed'],
      ['vfd', 'speed', 'sensor'],
      ['sump'],
    ],
    "condenserBoiler": [
      ['boiler', 'enable'],
      ['boiler', 'runtime'],
      ['power', 'elec', 'boiler'],
      ['energy', 'boiler'],
      ['boiler', 'run', 'cmd'],
      ["hot", "leaving", "temp", "water", "sensor"],
      ["hot", "entering", "temp", "water", "sensor"],
      ["hot", "water", "sp"],
      ['boiler', 'alarm'],
      ['boiler', 'status'],
    ],
    "coolingTowerTwoFan": [
      ['coolingTower', 'enable'],
      ['coolingTower', 'run', 'time'],
      ['power', 'elec', 'coolingTower'],
      ['coolingTower', 'energy'],
      ['coolingTower', 'run', 'cmd'],
      ['coolingTower', 'alarm'],
      ['coolingTower', 'status'],
      ['building', 'schedule'],
      ['vfd', 'speed'],
      ['vfd', 'speed', 'sensor'],
      ['sump'],
    ],
    "coolingTowerFourFan": [
      ['coolingTower', 'enable'],
      ['coolingTower', 'run', 'time'],
      ['power', 'elec', 'coolingTower'],
      ['coolingTower', 'energy'],
      ['coolingTower', 'run', 'cmd'],
      ['coolingTower', 'alarm'],
      ['coolingTower', 'status'],
      ['building', 'schedule'],
      ['vfd', 'speed'],
      ['vfd', 'speed', 'sensor'],
      ['sump'],
    ],
    "pump1": [
      ['pump', 'enable'],
      ['pump', 'run', 'time'],
      ['pump', 'power', 'elec'],
      ['pump', 'energy'],
      ['pump', 'cmd', 'run'],
      ['vfd', 'speed'],
      ['vfd', 'speed', 'sensor'],
      ['pump', 'alarm'],
      ['pump', 'status'],
    ]
  }