import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddPointModelComponent } from '../add-point-model/add-point-model.component';
import { uniq, includes, isEqual, findIndex } from 'lodash-es';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { StorageService } from '@/shared/services/storage.service';
import { ApiService } from '@/shared/services/api.service';
import { timeZones } from '@/shared/constants/timezones';
import { ObjectUtil } from '@/shared/utils/object-util';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map, of } from 'rxjs';
import { HelperMethodsService } from '@/cpm/shared/services/helper-methods.service';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import { CpmService } from '@/shared/services/cpm.service';
dayjs.extend(timezone);

@Component({
  selector: 'app-cpm-info-bar',
  templateUrl: './cpm-info-bar.component.html',
  styleUrls: ['./cpm-info-bar.component.scss']
})
export class CpmInfoBarComponent implements OnInit {
  @Input() buildings = [];
  @Input() themeClass: boolean;
  @Input() plantId;
  @Input() loginUserTypeData;
  @Input() editor_mode;
  @Output() bottomBarSaved = new EventEmitter<boolean>();
  @ViewChild('divToScroll') divToScroll: ElementRef;
  today: any = dayjs();
  allPointsData: Array<any> = [];

  pointLimit: number = 4;
  totalPoints: number;
  totalPage: number;
  currentpage: number;
  selectedInfoPoint: Array<any> = [];

  listpointData: Array<any> = [];
  infotooltipCount: number = 25;
  edittooltipCount: number = 30;
  isshowEditPopup: boolean = false;
  isDisableTop: boolean = true;
  isDisableBottom: boolean = false;
  infoPoints: Array<any> = [];
  showDeleteWarning: boolean = false;
  deletePointId: any = {};
  clickedInside: boolean = false;
  configId: any;
  masterPointList = [];
  temperatureInfo: any;
  timeZone: any;
  hayStackTimeZones : any = {};
  duplicateMasterPointList = [];

  constructor(private dialog: MatDialog,
    public cpmService: CpmService,
    public storageService: StorageService,
    private apiService: ApiService,
    private helperMethodsService: HelperMethodsService) {
      this.hayStackTimeZones = timeZones;
  }

  ngOnInit(): void {
    this.getWeatherAndTimeZoneInfo();
  }

  loadInfoPoints() {
    this.listpointData = [];
 
    const mapArray = this.masterPointList.map(({pointId}) => pointId);
    this.allPointsData = this.allPointsData.sort((a, b) => {
      return mapArray.indexOf(a.id) - mapArray.indexOf(b.id);
    });
    this.allPointsData.forEach((point) => {
      point.dis = point?.shortDis ? point?.shortDis : point?.dis;
      const found = this.masterPointList.find(_masterPt => _masterPt.pointId == point.id);
      point['master'] = (found?.master == true || found?.master == false) ? found?.master: false;
      this.listpointData.push(point);
    });
    this.totalPoints = this.listpointData.length;
    this.totalPage = Math.ceil(this.totalPoints / this.pointLimit)
    this.currentpage = 1;

    this.changePoints(this.currentpage);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.plantId?.currentValue) {
      this.plantId = changes?.plantId?.currentValue;
    }
    if (!isEqual(changes?.editor_mode?.currentValue, changes?.editor_mode?.previousValue) || changes?.plantId?.currentValue) {
      this.infoPoints = [];
      this.masterPointList = [];
      this.getConfigIdOfBottomBar();
    }
  }

  changePoints(page) {
    this.infoPoints = [];
    for (let i = (page - 1) * this.pointLimit; i < (page * this.pointLimit) && i < this.listpointData.length; i++) {
      this.infoPoints.push(this.listpointData[i]);
    }

    if (this.currentpage == 1) {
      this.isDisableBottom = false;
      this.isDisableTop = true;
    } else if (this.currentpage == this.totalPage) {
      this.isDisableBottom = true;
      this.isDisableTop = false;
    } else {
      this.isDisableBottom = false;
      this.isDisableTop = false;
    }
  }

  upArrowClicked() {
    this.currentpage = this.currentpage - 1;
    this.changePoints(this.currentpage);

  }

  downArrowClicked() {
    this.currentpage = this.currentpage + 1;
    this.changePoints(this.currentpage);
  }


  deletePoint(point) {
    this.showDeleteWarning = true;
    this.deletePointId = point;
  }

  cancelDelete() {
    this.showDeleteWarning = false;
    this.deletePointId = {};
    this.masterPointList = ObjectUtil.deepClone(this.duplicateMasterPointList);
  }

  confirmDeletePoint() {
    if (this.deletePointId.master) {
      let getIndex = findIndex(this.selectedInfoPoint, this.deletePointId);
      this.selectedInfoPoint.splice(getIndex, 1);
      this.showDeleteWarning = false;
    };
    this.masterPointList = this.masterPointList.filter(_point => _point.pointId != this.deletePointId.pointId);
    this.showDeleteWarning = false;
  }

  addPointModel() {
    const self = this;
    const dialogRef = self.dialog.open(AddPointModelComponent, {
      panelClass: 'cpmAddPoint-modal-container',
      width: '80%',
      data: {
        sites: self.buildings || [],
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.length > 0) {
          let existingPointIds = this.allPointsData.map(point => point.id);
          result.forEach(point => {
            if (!includes(existingPointIds, point.id)) {
              point.master = true;
              this.allPointsData.push(point);
            }
          })
        };
        this.masterPointList = this.allPointsData.map(_point => {
          return {
            id: _point.id,
            pointId: _point.id,
            pointName: _point?.dis,
            master: true,
            equipRef: "",
            click: false,
            hover: false,
          }
        })
        this.allPointsData = uniq(this.allPointsData);
        this.saveBottomBarConfigs();
      }
    });
  }

  loadBottomBarPoints(res){
    if (res?.error && res?.status == 400) {
  
    } else {
      if (res?.hisPointsData) {
        this.allPointsData = res?.hisPointsData?.rows;
      }
      if (res?.writablePointsData?.rows) {
        this.masterPointList.forEach(_masterPt => {
          let found = res?.writablePointsData?.rows?.find(_writePt => _masterPt.pointId == _writePt.id);
          if (found) {
            found = this.helperMethodsService.formatEnums(found);
            if (found.data.length && _masterPt?.pointType == 'WRITABLE') {
              found.data[0].val = found.enum.find(_val => found.data[0].val == _val.value).name;
              this.allPointsData.push(found);
            }
          }
        });
      }
      if (res?.readPointsData) {
        this.masterPointList.forEach(_masterPt => {
          let found = res?.readPointsData?.rows?.find(_writePt => _masterPt.pointId == _writePt.id);
          if (found) {
            found = this.helperMethodsService.formatEnums(found);
            if (found && found.data.length && _masterPt?.pointType == 'READABLE') {
              found.data[0].val = found.enum.find(_val => found.data[0].val == _val.value).name;
              this.allPointsData.push(found);
            }
          }
        });
      }
      this.loadInfoPoints();
    }
  }

  getMasterPointView() {
    let body = {
      "statusType":  this.editor_mode == 'edit' ? 'DRAFT':'PUBLISHED',
      "isTrend": "false",
      "viewType": "MASTER"
    }
    if(this.loginUserTypeData.isPublic){
      this.apiService.getSharedMasterData(this.loginUserTypeData.linkShareUuid, 'bottomBar',body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)).subscribe((res) => {
        this.loadBottomBarPoints(res);
      });
    }else if(!this.loginUserTypeData.isPublic){
      this.apiService.getMasterPoint(this.plantId, 'bottomBar', body).pipe(catchError(err => of(err)), map(this.cpmService.stripHaystackTypeMapping)).subscribe((res) => {
        this.loadBottomBarPoints(res);
      });
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.masterPointList, event.previousIndex, event.currentIndex);
  }

  showEllipses(name, limit) {
    if (name.length > limit) {
      return name.slice(0, limit) + '...';
    } else {
      return name;
    }
  }

  showEditPopup(isShow) {
    this.isshowEditPopup = isShow;
    this.divToScroll.nativeElement.scrollTop = 0;
    if (isShow) {
      let selectedInfoPoint = [];
      this.deletePointId = {};

      this.allPointsData.forEach((point) => {
        selectedInfoPoint.push(point);
      });
      this.selectedInfoPoint = [...selectedInfoPoint];
    } else {
      this.masterPointList = ObjectUtil.deepClone(this.duplicateMasterPointList);
    }
  }

  deleteWarning() {
    this.showDeleteWarning = true;
  }

  savePoint() {
    this.allPointsData = this.allPointsData.map(_point => {
      const found = this.masterPointList.find(_masterPt => _masterPt.pointId == _point.id);
      if (found) {
        _point.master = found.master;
      }
      return _point;
    });
    this.saveBottomBarConfigs();
  }

  buildTheRequest() {
    let array = this.masterPointList;
    if (!this.masterPointList.length) {
      array = this.allPointsData;
    }
    if (this.allPointsData?.length == 1 && !this.masterPointList?.length) {
      const found = this.allPointsData.find(_pt => (_pt.id == this.deletePointId.pointId));
      if (found) {
        array = [];
      }
    }
    return {
      plantId: this.plantId,
      equipId: 'bottomBar',
      statusType: "DRAFT",
      pointList: array?.length ? array.map(_masterPoint => {
        const _point: any = this.allPointsData.find(pt => pt.id == _masterPoint.pointId);
        const displayName:any = _point?.shortDis ? _point.shortDis : _point?.dis;
        return {
          pointId: _masterPoint?.pointId ? _masterPoint?.pointId: _masterPoint?.id,
          pointName: _masterPoint?.pointName ? _masterPoint.pointName : displayName,
          master: (_masterPoint?.master == true || _masterPoint?.master == false) ? _masterPoint?.master : true,
          equipRef: "",
          click: false,
          hover: false,
          pointType: _point ? this.setPointType(_point): _masterPoint.pointType
        }
      }) : []
    };
  }

  setPointType(key) {
    let pointType = '';
    if (key.hasOwnProperty('his')) {
      pointType = 'HIS';
    } else if (pointType != 'HIS' && key.hasOwnProperty('writable')) {
      pointType = 'WRITABLE';
    }
    return pointType == '' ? 'READABLE' : pointType;
  }

  assignConfigBottomData(response){
    if (response) {
      if (response.configId) {
        this.configId = response.configId;
      }
      if (response.pointList) {
        this.masterPointList = response.pointList;
        this.duplicateMasterPointList = ObjectUtil.deepClone(response.pointList);
      }
    }
  }

  getConfigIdOfBottomBar() {
    if (this.loginUserTypeData.isPublic) {
      this.apiService.getSharedConfig(this.loginUserTypeData.linkShareUuid, 'bottomBar').subscribe({
        next: (response) => {
          this.assignConfigBottomData(response);
        }, error: (error) => {

        }, complete: () => {
          this.getMasterPointView();
        }
      });
    } else if (!this.loginUserTypeData.isPublic) {
      let status = this.editor_mode == 'edit' ? 'DRAFT':'PUBLISHED'
      this.apiService.getEquipConfigs({ "plantId": this.plantId, "equipId": 'bottomBar', status: status }).subscribe({
        next: (response) => {
          this.assignConfigBottomData(response);
        }, error: (error) => {

        }, complete: () => {
          this.getMasterPointView();
        }
      });
    }
  }

  // changeSelection() {
  //   this.saveBottomBarConfigs();
  // }

  saveBottomBarConfigs() {
    const requestBody:any = this.buildTheRequest();
    if(this.configId){
      requestBody.configId = this.configId;
    }
    this.apiService.saveEquipConfigs(requestBody).subscribe((response) => {
      if (response) {
        if (response?.pointList && response?.pointList?.length) {
          this.masterPointList = response.pointList;
          this.duplicateMasterPointList = ObjectUtil.deepClone(response.pointList);
        } else {
          this.masterPointList = [];
          this.duplicateMasterPointList = [];
        }
      }
      this.getMasterPointView();
      this.bottomBarSaved.emit(true)
    });
  }

  getWeatherAndTimeZoneInfo() {
    this.apiService.getWeatherAndTimeZone(this.plantId).subscribe({
      next: (res) => {
        this.temperatureInfo = res.airTemp;
        this.timeZone = res.timeZone.split('"')[1];
        setInterval(() => {
          this.today = dayjs().tz(this.hayStackTimeZones[this.timeZone]);
        }, 60000);
      }
    })
  }
}
