export const preConfiguredNodes = {
    waterCooledChiller: {id:'waterCooledChiller', name: 'waterCooledChiller',inputs: 0, outputs: 4,displayName :"Water Cooled Chiller", cutAwayImg: "01_Water_Cooled_Chiller_Cutaway.png", img:"01_Water_Cooled_Chiller.png", margin: "fourRow_OneCol", width:"320px", height:"384px", static: false, pointLimit: 9 },
    airCooledChiller: {id:'airCooledChiller',name:'airCooledChiller',inputs:0,outputs:2, displayName :"Air Cooled Chiller", img:"02_Air_Cooled_Chiller.png", cutAwayImg: "02_Air_Cooled_Chiller_Cutaway.png", margin: "fourRow_OneCol", width:"320px", height:"384px", static: false, pointLimit: 9 },
    pump1Vertical: {id:'pump1Vertical',name:'pump1Vertical',inputs:1,outputs:1, displayName :"Pump Vertical", cutAwayImg:"06_Pump1_Cutaway.png", img:"06_Pump1vertical_backward.png", margin: "oneRow_oneCol", width:"128px", height:"128px", static: false, pointLimit: 7 },
    coolingTowerSingleFan: {id:'coolingTowerSingleFan',name:'coolingTowerSingleFan',inputs:2,outputs:0, displayName :"Cooling Tower - 1 Fan", cutAwayImg:"04_Cooling_Tower_Single_Fan_Cutaway.png", img:"04_Cooling_Tower_Single_Fan.png", margin: "oneRow_oneCol", width:"384px", height:"384px", static: false, pointLimit: 8 },
    coolingTowerTwoFan: {id:'coolingTowerTwoFan',name:'coolingTowerTwoFan',inputs:3,outputs:0, displayName :"Cooling Tower - 2 Fan", cutAwayImg:"04_Cooling_Tower_Two_Fan_Cutaway.png", img:"04_Cooling_Tower_Two_Fan.png", margin: "twoRow_twoCol", width:"384px", height:"384px", static: false, pointLimit: 11 },
    coolingTowerFourFan: {id:'coolingTowerFourFan',name:'coolingTowerFourFan',inputs:5,outputs:0, displayName :"Cooling Tower - 4 Fan", cutAwayImg:"04_Cooling_Tower_Four_Fan_Cutaway.png", img:"04_Cooling_Tower_Four_Fan.png", margin: "oneRow_oneCol", width:"448px", height:"448px", static: false, pointLimit: 17 },
    steamBoiler: {id:'steamBoiler',name:'steamBoiler',inputs:0,outputs:2, displayName :"Steam Boiler", cutAwayImg:"08_Gas_Boiler_Cutaway.png", img:"08_Gas_Boiler.png", margin: "oneRow_oneCol", width:"320px", height:"384px", static: false , pointLimit: 9},
    condenserBoiler: {id:'condenserBoiler',name:'condenserBoiler',inputs:0,outputs:2, displayName :"Condenser Boiler", cutAwayImg:"07_Condensing_Boiler_Cutaway.png", img:"07_Condensing_Boiler.png", margin: "twoRow_twoCol", width:"320px", height:"384px", static: false, pointLimit: 9 },
    heatExchanger: {id:'heatExchanger',name:'heatExchanger',inputs:0,outputs:2, displayName :"Heat Exchanger", img:"14_Heat_Exchanger.png", margin: "oneRow_oneCol", width:"320px", height:"384px", static: false , pointLimit: 1},
    chilledWaterReturnHeaderPipe: { id: 'chilledWaterReturnHeaderPipe',name: 'chilledWaterReturnHeaderPipe', inputs: 2, outputs: 0, displayName: 'Chilled Water Return Header', img: 'Chilled_Water_Return_Header.png', margin: 'oneRow_oneCol', width: '320px', height: '384px', static: false,subEquips: [], pointLimit: 0 },
    chilledWaterSupplyHeaderPipe: { id: 'chilledWaterSupplyHeaderPipe',name: 'chilledWaterSupplyHeaderPipe', inputs: 2,outputs: 0,displayName: 'Chilled Water Supply Header',img: 'Chilled_Water_Supply_Header.png', margin: 'oneRow_oneCol', width: '320px', height: '384px', static: false, pointLimit: 0 },
    condenserWaterReturnHeaderPipe:{ id: 'condenserWaterReturnHeaderPipe',name: 'condenserWaterReturnHeaderPipe',inputs: 2,outputs: 0, displayName: 'Condenser Water Return Header',img: 'Condenser_Water_Return_Header.png',margin: 'oneRow_oneCol', width: '320px',height: '384px',static: false, pointLimit: 0 },
    condenserWaterSupplyHeaderPipe:{id: 'condenserWaterSupplyHeaderPipe',name: 'condenserWaterSupplyHeaderPipe',inputs: 2,outputs: 0,displayName: 'Condenser Water Supply Header',img: 'Condenser_Water_Supply_Header.png',margin: 'oneRow_oneCol',width: '320px',height: '384px',static: false, pointLimit: 0 },
    hotWaterReturnHeaderPipe:{id: 'hotWaterReturnHeaderPipe',name: 'hotWaterReturnHeaderPipe',inputs: 2,outputs: 0,displayName: 'Hot Water Return Header',img: 'Hot_Water_Return_Header.png',margin: 'oneRow_oneCol',width: '320px',height: '384px',static: false, pointLimit: 0 },
    hotWaterSupplyHeaderPipe:{id: 'hotWaterSupplyHeaderPipe', name: 'hotWaterSupplyHeaderPipe',inputs: 2,outputs: 0, displayName: 'Hot Water Supply Header',img: 'Hot_Water_Supply_Header.png', margin: 'oneRow_oneCol',width: '320px',height: '384px',static: false, pointLimit: 0 },
    chilledWaterBypassHeaderPipe: { id: 'chilledWaterBypassHeaderPipe',name: 'chilledWaterBypassHeaderPipe', inputs: 2, outputs: 0, displayName: 'Chilled Water Bypass Header', img: 'Chilled_Water_Return_Header.png', margin: 'oneRow_oneCol', width: '320px', height: '384px', static: false,subEquips: [], pointLimit: 0 },
    condenserWaterBypassHeaderPipe: { id: 'condenserWaterBypassHeaderPipe',name: 'condenserWaterBypassHeaderPipe', inputs: 2, outputs: 0, displayName: 'Condenser Water Bypass Header', img:  'Condenser_Water_Return_Header.png', margin: 'oneRow_oneCol', width: '320px', height: '384px', static: false,subEquips: [], pointLimit: 0 },
    hotWaterBypassHeaderPipe: { id: 'hotWaterBypassHeaderPipe',name: 'hotWaterBypassHeaderPipe', inputs: 2, outputs: 0, displayName: 'Hot Water Bypass Header', img: 'Hot_Water_Return_Header.png', margin: 'oneRow_oneCol', width: '320px', height: '384px', static: false,subEquips: [], pointLimit: 0 },
    dualTempReturnHeaderPipe: { id: 'dualTempReturnHeaderPipe',name: 'dualTempReturnHeaderPipe', inputs: 2, outputs: 0, displayName: 'Dual Temperature Return Header', img: 'dualtempReturn.png', margin: 'oneRow_oneCol', width: '320px', height: '384px', static: false,subEquips: [], pointLimit: 0 },
    dualTempSupplyHeaderPipe: { id: 'dualTempSupplyHeaderPipe',name: 'dualTempSupplyHeaderPipe', inputs: 2, outputs: 0, displayName: 'Dual Temperature Supply Header', img: 'dualtempSupply.png', margin: 'oneRow_oneCol', width: '320px', height: '384px', static: false,subEquips: [], pointLimit: 0 },
    dualTempBypassHeaderPipe: { id: 'dualTempBypassHeaderPipe',name: 'dualTempBypassHeaderPipe', inputs: 2, outputs: 0, displayName: 'Dual Temperature Bypass Header', img:  'dualtempReturn.png', margin: 'oneRow_oneCol', width: '320px', height: '384px', static: false,subEquips: [], pointLimit: 0 },
    valveActuator: { id: 'valveActuator', name: 'valveActuator', class: 'valveActuator', inputs: 1, outputs: 1, displayName: "Valve w/ Actuator", img: "03_Valve_2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 2 },
    valveActuator_forward: { id: 'valveActuator_forward', name: 'valveActuator_forward', class: 'valveActuator_forward', inputs: 1, outputs: 1, displayName: "Valve w/ Actuator", img: "03_Valve_2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 2 },
    valveActuator_backward: { id: 'valveActuator_backward', name: 'valveActuator_backward', class: 'valveActuator_backward', inputs: 1, outputs: 1, displayName: "Valve w/ Actuator", img: "03_Valve_2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 2 },
    temperatureSensor: { id: 'temperatureSensor', name: 'temperatureSensor', class: 'temperatureSensor', inputs: 1, outputs: 1, displayName: "Temperature Sensor", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    temperatureSensor_forward: { id: 'temperatureSensor_forward', name: 'temperatureSensor_forward', class: 'temperatureSensor_forward', inputs: 1, outputs: 1, displayName: "Temperature Sensor", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    temperatureSensor_backward: { id: 'temperatureSensor_backward', name: 'temperatureSensor_backward', class: 'temperatureSensor_backward', inputs: 1, outputs: 1, displayName: "Temperature Sensor", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    wellTemperature : { id: 'wellTemperature', name: 'wellTemperature', class: 'wellTemperature', inputs: 1, outputs: 1, displayName: "Well Temperature", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    pressureSensor_forward: { id: 'pressureSensor_forward', name: 'pressureSensor_forward', class: 'pressureSensor_forward', inputs: 1, outputs: 1, displayName: "Pressure Sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    pressureSensor_backward: { id: 'pressureSensor_backward', name: 'pressureSensor_backward', class: 'pressureSensor_backward', inputs: 1, outputs: 1, displayName: "Pressure Sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    wellTemperature_forward: { id: 'wellTemperature_forward', name: 'wellTemperature_forward', class: 'wellTemperature_forward', inputs: 1, outputs: 1, displayName: "Well Temperature", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    wellTemperature_backward: { id: 'wellTemperature_backward', name: 'wellTemperature_backward', class: 'wellTemperature_backward', inputs: 1, outputs: 1, displayName: "Well Temperature", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    diffPressureSensor: { id: 'diffPressureSensor', name: 'diffPressureSensor', class: 'diffPressureSensor', inputs: 1, outputs: 1, displayName: "Diff pressure sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    diffPressureSensor_forward :{ id: 'diffPressureSensor_forward', name: 'diffPressureSensor_forward', class: 'diffPressureSensor_forward', inputs: 1, outputs: 1, displayName: "Diff pressure sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    diffPressureSensor_backward: { id: 'diffPressureSensor_backward', name: 'diffPressureSensor_backward', class: 'diffPressureSensor_backward', inputs: 1, outputs: 1, displayName: "Diff pressure sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    flowSensor: { id: 'flowSensor', name: 'flowSensor', class: 'flowSensor', inputs: 1, outputs: 1, displayName: "Flow Sensor", img: "10_Flow_Meter1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    flowSensor_forward: { id: 'flowSensor_forward', name: 'flowSensor_forward', class: 'flowSensor_forward', inputs: 1, outputs: 1, displayName: "Flow Sensor", img: "10_Flow_Meter1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    flowSensor_backward: { id: 'flowSensor_backward', name: 'flowSensor_backward', class: 'flowSensor_backward', inputs: 1, outputs: 1, displayName: "Flow Sensor", img: "10_Flow_Meter1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    flowSwitch: { id: 'flowSwitch', name: 'flowSwitch', class: 'flowSwitch', inputs: 1, outputs: 1, displayName: "Flow Switch", img: "05_Flow_Switch.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    flowSwitch_forward: { id: 'flowSwitch_forward', name: 'flowSwitch_forward', class: 'flowSwitch_forward', inputs: 1, outputs: 1, displayName: "Flow Switch", img: "05_Flow_Switch.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 1 },
    flowSwitch_backward: { id: 'flowSwitch_backward', name: 'flowSwitch_backward', class: 'flowSwitch_backward', inputs: 1, outputs: 1, displayName: "Flow Switch", img: "05_Flow_Switch.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true , pointLimit: 1},
    btuMeter: { id: 'btuMeter', name: 'btuMeter', class: 'btuMeter', inputs: 1, outputs: 1, displayName: "BTU Meter", img: "16_btu_meter.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true , pointLimit: 6},
    connector :{id: 'Connector',displayName: 'Connector',name: 'connectTwo',inputs: 1,outputs: 1,class: 'connectTwo', pointLimit: 0 },
    building2Pipe: {id: 'building2Pipe',name: 'building2Pipe',inputs: 2, outputs: 0, displayName: 'Building Load Block', img: '15_Building_2_Pipe.png', margin: 'oneRow_oneCol',width: '320px',height: '384px',static: false, pointLimit: 1},
    dualTempBuilding2Pipe: {id: 'dualTempBuilding2Pipe',name: 'dualTempBuilding2Pipe',inputs: 2, outputs: 0, displayName: 'Building Load Block', img: '15_Building_2_Pipe.png', margin: 'oneRow_oneCol',width: '320px',height: '384px',static: false, pointLimit: 1},
    building2PipeHot: {id: 'building2PipeHot',name: 'building2PipeHot',inputs: 2, outputs: 0, displayName: 'Building Load Block', img: '15_Building_2_Pipe.png', margin: 'oneRow_oneCol',width: '320px',height: '384px',static: false, pointLimit: 1,},
    threeWayValveDivert_forward: { id: 'threeWayValveDivert_forward', name: 'threeWayValveDivert_forward', class: 'threeWayValveDivert_forward', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
    threeWayValveDivert_backward: { id: 'threeWayValveDivert_backward', name: 'threeWayValveDivert_backward', class: 'threeWayValveDivert_backward', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
    threeWayValvemixing_forward: { id: 'threeWayValvemixing_forward', name: 'threeWayValvemixing_forward', class: 'threeWayValvemixing_forward', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
    threeWayValvemixing_backward: { id: 'threeWayValvemixing_backward', name: 'threeWayValvemixing_backward', class: 'threeWayValvemixing_backward', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
    heatExchanger_forward: {id: 'heatExchanger_forward',name: 'heatExchanger_forward',inputs: 2, outputs: 2, displayName: 'Heat Exchanger', img: 'Heat_Exchanger_forward.png', margin: 'oneRow_oneCol', width: '249px', height: '169px',static: false, pointLimit: 9},
    heatExchanger_backward: {id: 'heatExchanger_backward',name: 'heatExchanger_backward',inputs: 2, outputs: 2, displayName: 'Heat Exchanger', img: 'Heat_Exchanger_backward.png', margin: 'oneRow_oneCol', width: '249px', height: '169px',static: false, pointLimit: 9},
  }
export const ElementsToBeConfiguredNodes: any[] = [
  { id: 'label', name: 'Elements To Be Configured', displayName: 'ELEMENTS TO BE CONFIGURED', label: true, width: '40px', height: '106px' },
  { id: 'valveActuator', name: 'valveActuator', class: 'valveActuator', inputs: 1, outputs: 1, displayName: "Valve w/ Actuator", img: "03_Valve_2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'valveActuator_forward', name: 'valveActuator_forward', class: 'valveActuator_forward', inputs: 1, outputs: 1, displayName: "Valve w/ Actuator", img: "03_Valve_2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'valveActuator_backward', name: 'valveActuator_backward', class: 'valveActuator_backward', inputs: 1, outputs: 1, displayName: "Valve w/ Actuator", img: "03_Valve_2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'temperatureSensor', name: 'temperatureSensor', class: 'temperatureSensor', inputs: 1, outputs: 1, displayName: "Temperature Sensor", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'temperatureSensor_forward', name: 'temperatureSensor_forward', class: 'temperatureSensor_forward', inputs: 1, outputs: 1, displayName: "Temperature Sensor", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'temperatureSensor_backward', name: 'temperatureSensor_backward', class: 'temperatureSensor_backward', inputs: 1, outputs: 1, displayName: "Temperature Sensor", img: "11_Well_Temperature Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'wellTemperature', name: 'wellTemperature', class: 'wellTemperature', inputs: 1, outputs: 1, displayName: "Well Temperature", img: "11_Well_Temperature Sensor.png", margin: "twoRow_twoCol", width: "64px", height: "64px", static: true },
  { id: 'pressureSensor_forward', name: 'pressureSensor_forward', class: 'pressureSensor_forward', inputs: 1, outputs: 1, displayName: "Pressure Sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'pressureSensor_backward', name: 'pressureSensor_backward', class: 'pressureSensor_backward', inputs: 1, outputs: 1, displayName: "Pressure Sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'wellTemperature', name: 'wellTemperature', class: 'wellTemperature', inputs: 1, outputs: 1, displayName: "Well Temperature", img: "11_Well_Temperature Sensor.png", margin: "twoRow_twoCol", width: "64px", height: "64px", static: true },
  { id: 'wellTemperature_forward', name: 'wellTemperature_forward', class: 'wellTemperature_forward', inputs: 1, outputs: 1, displayName: "Well Temperature", img: "11_Well_Temperature Sensor.png", margin: "twoRow_twoCol", width: "64px", height: "64px", static: true },
  { id: 'wellTemperature_backward', name: 'wellTemperature_backward', class: 'wellTemperature_backward', inputs: 1, outputs: 1, displayName: "Well Temperature", img: "11_Well_Temperature Sensor.png", margin: "twoRow_twoCol", width: "64px", height: "64px", static: true },
  { id: 'diffPressureSensor', name: 'diffPressureSensor', class: 'diffPressureSensor', inputs: 1, outputs: 1, displayName: "Diff pressure sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'diffPressureSensor_forward', name: 'diffPressureSensor_forward', class: 'diffPressureSensor_forward', inputs: 1, outputs: 1, displayName: "Diff pressure sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'diffPressureSensor_backward', name: 'diffPressureSensor_backward', class: 'diffPressureSensor_backward', inputs: 1, outputs: 1, displayName: "Diff pressure sensor", img: "09_Pressure_Sensor.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'flowSensor', name: 'flowSensor', class: 'flowSensor', inputs: 1, outputs: 1, displayName: "Flow Sensor", img: "10_Flow_Meter1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'flowSensor_forward', name: 'flowSensor_forward', class: 'flowSensor_forward', inputs: 1, outputs: 1, displayName: "Flow Sensor", img: "10_Flow_Meter1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'flowSensor_backward', name: 'flowSensor_backward', class: 'flowSensor_backward', inputs: 1, outputs: 1, displayName: "Flow Sensor", img: "10_Flow_Meter1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'flowSwitch', name: 'flowSwitch', class: 'flowSwitch', inputs: 1, outputs: 1, displayName: "Flow Switch", img: "05_Flow_Switch.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'flowSwitch_forward', name: 'flowSwitch_forward', class: 'flowSwitch_forward', inputs: 1, outputs: 1, displayName: "Flow Switch", img: "05_Flow_Switch.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'flowSwitch_backward', name: 'flowSwitch_backward', class: 'flowSwitch_backward', inputs: 1, outputs: 1, displayName: "Flow Switch", img: "05_Flow_Switch.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'btuMeter', name: 'btuMeter', class: 'btuMeter', inputs: 1, outputs: 1, displayName: "BTU Meter", img: "16_btu_meter.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'btuMeter_forward', name: 'btuMeter_forward', class: 'btuMeter', inputs: 1, outputs: 1, displayName: "BTU Meter", img: "16_btu_meter.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id: 'btuMeter_backward', name: 'btuMeter_backward', class: 'btuMeter', inputs: 1, outputs: 1, displayName: "BTU Meter", img: "16_btu_meter.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true },
  { id:'pump1Vertical',name:'pump1Vertical',class:'pump1Vertical',inputs:1,outputs:1, displayName :"Pump Vertical", cutAwayImg:"06_Pump1_Cutaway.png", img:"06_Pump1vertical_backward.png", margin: "oneRow_oneCol", width:"128px", height:"128px", static: false },
  { id:'pump1Vertical_forward',name:'pump1Vertical_forward',class:'pump1Vertical_forward',inputs:1,outputs:1, displayName :"Pump Vertical", cutAwayImg:"06_Pump1_Cutaway.png", img:"06_Pump1vertical_forward.png", margin: "oneRow_oneCol", width:"128px", height:"128px", static: false },
  { id:'pump1Vertical_backward',name:'pump1Vertical_backward',class:'pump1Vertical_backward',inputs:1,outputs:1, displayName :"Pump Vertical", cutAwayImg:"06_Pump1_Cutaway.png", img:"06_Pump1vertical_backward.png", margin: "oneRow_oneCol", width:"128px", height:"128px", static: false },
  {
    id: 'Connector',
    displayName: 'Connector',
    name: 'connectTwo',
    inputs: 1,
    outputs: 1,
    class: 'connectTwo'
  },
  { id: 'threeWayValveDivert', name: 'threeWayValveDivert', class: 'threeWayValveDivert', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
  { id: 'threeWayValvemixing', name: 'threeWayValvemixing', class: 'threeWayValvemixing', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
  { id: 'threeWayValveDivert_forward', name: 'threeWayValveDivert_forward', class: 'threeWayValveDivert_forward', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
  { id: 'threeWayValveDivert_backward', name: 'threeWayValveDivert_backward', class: 'threeWayValveDivert_backward', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
  { id: 'threeWayValvemixing_forward', name: 'threeWayValvemixing_forward', class: 'threeWayValvemixing_forward', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve1.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 },
  { id: 'threeWayValvemixing_backward', name: 'threeWayValvemixing_backward', class: 'threeWayValvemixing_backward', inputs: 2, outputs: 1, displayName: "3 way Valve", img: "14_3way_valve2.png", margin: "oneRow_oneCol", width: "64px", height: "64px", static: true, pointLimit: 3 }
] 

export const equipGroupList: any = {
  bypassPipeList: [
    "chilledWaterBypassHeaderPipe",
    "condenserWaterBypassHeaderPipe",
    "hotWaterBypassHeaderPipe",
    "dualTempBypassHeaderPipe"
  ],

  headerPipeList: [
    "chilledWaterReturnHeaderPipe",
    "chilledWaterSupplyHeaderPipe",
    "condenserWaterReturnHeaderPipe",
    "condenserWaterSupplyHeaderPipe",
    "hotWaterReturnHeaderPipe",
    "hotWaterSupplyHeaderPipe",
    "dualTempReturnHeaderPipe",
    "dualTempSupplyHeaderPipe"
  ],

  parentEquipList: [
    "waterCooledChiller",
    "airCooledChiller",
    "steamBoiler",
    "condenserBoiler",
    "coolingTowerSingleFan",
    "coolingTowerTwoFan",
    "coolingTowerFourFan",
    "heatExchanger",
    "building2Pipe",
    "building2PipeHot",
    "dualTempBuilding2Pipe",
    "pump1Vertical_forward",
    "pump1Vertical_backward",
    "heatExchanger_forward",
    "heatExchanger_backward"
  ],

  btuandPumpList: [
    "btuMeter",
    "pump1Vertical",
    "pump1Vertical_forward",
    "pump1Vertical_backward"
  ],

  pumpList: [
    "pump1Vertical",
    "pump1Vertical_forward",
    "pump1Vertical_backward"
  ],

  dualTempPipeList: [
    "dualTempReturnHeaderPipe",
    "dualTempSupplyHeaderPipe"
  ],

  nonBuildingParentEquip: [
    "waterCooledChiller",
    "airCooledChiller",
    "steamBoiler",
    "condenserBoiler",
    "coolingTowerSingleFan",
    "coolingTowerTwoFan",
    "coolingTowerFourFan"
  ],

  buildingList: [
    "building2Pipe",
    "building2PipeHot",
    "dualTempBuilding2Pipe"
  ],

  dualTempEquip: [
    "dualTempBuilding2Pipe",
    "dualTempReturnHeaderPipe",
    "dualTempSupplyHeaderPipe"
  ],

  threeWayValveList: [
    "threeWayValveDivert_forward",
    "threeWayValveDivert_backward",
    "threeWayValvemixing_forward",
    "threeWayValvemixing_backward"
  ],

  headerPipeClassMapping: {
    'chilledWaterReturnHeaderPipe': "CWReturnClass",
    'chilledWaterSupplyHeaderPipe': "CWSupplyClass",
    'condenserWaterReturnHeaderPipe': "CDWReturnClass",
    'condenserWaterSupplyHeaderPipe': "CDWSupplyClass",
    'hotWaterReturnHeaderPipe': "boilerReturnClass",
    'hotWaterSupplyHeaderPipe': "boilerSupplyClass",
    'chilledWaterBypassHeaderPipe': "CWBypassClass",
    'condenserWaterBypassHeaderPipe': "CDWBypassClass",
    'hotWaterBypassHeaderPipe': "HotBypassClass",
    'dualTempHeaderPipe': "DTClass",
    'dualTempReturnHeaderPipe': 'DTReturnClass',
    'dualTempSupplyHeaderPipe': "DTSupplyClass",
    'dualTempBypassHeaderPipe': "DTBypassClass"
  },

  sensorEquipList: [
    "valveActuator_forward",
    "valveActuator_backward",
    "temperatureSensor",
    "temperatureSensor_forward",
    "temperatureSensor_backward",
    "wellTemperature",
    "wellTemperature_forward",
    "wellTemperature_backward",
    "pressureSensor_forward",
    "pressureSensor_backward",
    "diffPressureSensor_forward",
    "diffPressureSensor_backward",
    "flowSensor_forward",
    "flowSensor_backward",
    "flowSwitch_forward",
    "flowSwitch_backward",
    "btuMeter",
    "threeWayValveDivert_forward",
    "threeWayValveDivert_backward",
    "threeWayValvemixing_forward",
    "threeWayValvemixing_backward",
    "connectorLbend"
  ],

  mainEquipList: [
    "waterCooledChiller",
    "airCooledChiller",
    "steamBoiler",
    "condenserBoiler",
    "coolingTowerSingleFan",
    "coolingTowerTwoFan",
    "coolingTowerFourFan",
    "building2Pipe",
    "building2PipeHot",
    "dualTempBuilding2Pipe"
  ],

  connectorList:[
    "byPassLine-connectTwo",
    "subEquip-connectTwo",
    "connectorLbend",
    "connectTwo",
    "pipeDemarkation"
  ],

  chiller_Or_Boiler :[
    "waterCooledChiller",
    "airCooledChiller",
    "steamBoiler",
    "condenserBoiler",
  ],

  pipeColorMapping: {
    boilerSupplyClass: '#FFB3B3',
    boilerReturnClass: '#FFDBD0',
    CWSupplyClass: '#9DD1FF',
    CWReturnClass: '#D4EBFF',
    CDWSupplyClass: '#CBF6E4',
    CDWReturnClass: '#99EBC7',
    DTSupplyClass: '#b9b9b9',
    DTReturnClass: '#e4e4e4'
  },

  leadnLagColorMapping: {
    CWReturnClass: ['CWSupplyClass'],
    CWSupplyClass: ['CWReturnClass'],
    boilerReturnClass: ['boilerSupplyClass'],
    boilerSupplyClass: ['boilerReturnClass']
  },

  condensorPipeClass: ['CDWSupplyClass',
    'CDWReturnClass'],

  pipeColorObj: {
    light: {
      boilerSupplyObj: '#FFB3B3',
      boilerReturnObj: '#FFDBD0',
      chillerSupplyObj: '#9DD1FF',
      chillerReturnObj: '#D4EBFF',
      condenserSupplyObj: '#CBF6E4',
      condenserReturnObj: '#99EBC7',
    },
    dark: {
      boilerSupplyObj: '#8C4D4D',
      boilerReturnObj: '#AE7769',
      chillerSupplyObj: '#50799E',
      chillerReturnObj: '#A1BCD1',
      condenserSupplyObj: '#A8C9BB',
      condenserReturnObj: '#6DA38C',
    }
  }

}






