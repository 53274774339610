<div *ngIf="isshowEditPopup" id="overlay"></div>

<div class="equipment-bar-header" [ngClass]="themeClass ? 'dark-theme' : 'light-theme'">
    <div class="d-flex justify-content-between">
        <div>
            <span class="timeText"><em class="fa fa-temperature-low p-r-10"></em> <span>{{temperatureInfo}} F</span> </span>
        </div>
        <div *ngIf="infoPoints.length == 0 && editor_mode == 'edit'">
            <span class="noPointText">Add points to feeds</span>
            <span class="addPointText" (click)="addPointModel()">ADD POINTS</span>
        </div>
        <div *ngIf="infoPoints.length > 0">
            <span *ngFor="let info of infoPoints  let i = index;let last = last;" [attr.data-index]="i">
                <span *ngIf="infotooltipCount >= info.dis.length " class="infoPointName">{{info.dis}}</span>
                <span *ngIf="info.dis.length >  infotooltipCount" matTooltip="{{info.dis}}" matTooltipPosition="above"
                    matTooltipClass="pointnametooltip"
                    class="infoPointName">{{showEllipses(info.dis,infotooltipCount)}}</span>
                    <span [ngClass]="themeClass ? 'infoPointValue-darkview' : 'infoPointValue'" >{{(info?.data && info?.data?.length) ? info?.data[0]?.val: '-'}}</span>
                <span *ngIf="!last" class="editVerticalLine">|</span>
            </span>
            <div class="arrows" *ngIf="listpointData.length > pointLimit">
                <em (click)="upArrowClicked()" class="fa-solid fa-chevron-up cursor-pointer visible arrow-up"
                    [ngClass]="{'visibility-hidden' : isDisableTop, 'm-t-3': isDisableBottom}"></em>
                <em (click)="downArrowClicked()" class="fa-solid fa-chevron-down cursor-pointer visible arrow-down"
                    [ngClass]="{'visibility-hidden' : isDisableBottom, 'arrow-down-single' : isDisableTop}"></em>
            </div>
        </div>
        <div class="relative">
            <span *ngIf="editor_mode == 'edit'" (click)="showEditPopup(true)" class="editButton">
                EDIT
            </span>
            <div [ngClass]="{'showEditpopup' : isshowEditPopup}" class="editBox">
                <div class="d-flex">
                    <div class="statusFeedText">Status Feed</div>
                    <div (click)="[addPointModel(),showEditPopup(false)]" class="editAddpoint">ADD POINT</div>
                </div>

                <div class="selectedPoint p-b-6">
                    Selected Points
                </div>


                <div *ngIf="showDeleteWarning"  class="row align-items-start m-t-30 m-b-10 warningtext">
                    <div class="col-2 text-center">
                        <i class="fa fa-exclamation-triangle color-primary fa-lg m-t-10"></i>
                    </div>
                    <div class="col-10 p-l-0 pre-wrap">
                        <p class="warningtext">Are you sure you want to Delete the Point ?</p>

                        <div class="d-flex justify-content-end deleteWarningButton m-t-15">
                            <button (click)="cancelDelete()" class="btn btn-text"> Cancel</button>
                            <button (click)="confirmDeletePoint()" class="btn btn-text">
                                Delete</button>
                        </div>
                    </div>
                </div>

                <div *ngIf="!showDeleteWarning" class="pointContainer">
                    <div cdkDropList [cdkDropListData]="masterPointList"  #divToScroll  class="container" (cdkDropListDropped)="drop($event)">
                        <div class="oddBackground" *ngFor="let point of masterPointList" cdkDrag>
                            <div class="d-flex">
                                <div>
                                    <mat-checkbox [(ngModel)]="point.master">
                                        <span *ngIf="edittooltipCount >= point.pointName.length "
                                            class="editPointText">{{point.pointName}}</span>
                                        <span *ngIf="point.pointName.length >  edittooltipCount" matTooltip="{{point.pointName}}"
                                            matTooltipPosition="above" matTooltipClass="pointnametooltip"
                                            class="editPointText">{{showEllipses(point.pointName,edittooltipCount)}}</span>
                                    </mat-checkbox>
                                </div>
                                <div (click)="deletePoint(point)" class="deleteButton"><em
                                        class="fa-solid fa-xmark"></em></div>
                            </div>

                        </div>
                    </div>

                </div>

                <div *ngIf="!showDeleteWarning" class="d-flex justify-content-end align-items-center p-t-10">
                    <div (click)="showEditPopup(false)" class="btn color-primary editContainerBtn">CANCEL</div><span
                        class="verticalLine">|</span>

                    <div (click)="[savePoint(),showEditPopup(false)]" class="btn color-primary editContainerBtn">SAVE
                    </div>
                </div>

            </div> <span *ngIf="allPointsData?.length > 0 && editor_mode == 'edit'" class="p-r-20 editVerticalLine">|</span>
            <span class="timeText" id="time">{{ today | date: 'HH:mm' }}<span></span><em
                    class="fa fa-clock p-l-10"></em></span>
        </div>
    </div>
</div>
